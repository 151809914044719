import "./styles.css";
import { useContext, FunctionComponent } from "react";

const Status: FunctionComponent = (): JSX.Element => {
  return <div className="status-box">
    <div className="navbar">
      <div className="navbar-btn">
        fdf
      </div>
    </div>
    <p className="">
      test
    </p>
  </div>;
}

export default Status;
